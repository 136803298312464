import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtPartResource, type PtPartModel } from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { PartsList, ListHeader } from './components'

const PricingTiersParts = () => {
    const record = useRecordContext<ShopModel>()

    return (
        <ResourceContextProviderWithClearEffect value={getPtPartResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
            >
                <ListHeader />
                <PartsList />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default PricingTiersParts

const defaultSort: SortPayload<PtPartModel> = {
    field: 'created',
    order: Order.DESC,
}
