import { type FC } from 'react'

import { BooleanInput, useListContext, useRecordContext, useResourceContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    ListTotalBadge,
    ViewHeader,
    type ListBulkActions,
    type ListSortContentProps,
    datagridAvatarColumn,
} from 'components'
import {
    ResourceContextProviderWithClearEffect,
    useConfirm,
    useNotify,
    type SortPayload,
    type ActionChildren,
    deleteManyFromListAction,
    deleteOneAction,
    multiselectAction,
    api,
} from 'core'
import {
    type ExpirationModel,
    ExpirationEditor,
    expirationFields,
    useExpirationEditor,
} from 'resources/expirations'
import { type UnitModel } from 'resources/units'
import { IconBox, PageContent, formControlLabelClasses, Anchor } from 'ui'

interface Props {
    readOnly?: boolean
}

const UnitExpirations: FC<Props> = ({ readOnly: readOnlyProp }) => {
    const readOnly = readOnlyProp ?? undefined
    const unit = useRecordContext<UnitModel>()
    const resource = useResourceContext()

    if (!unit) {
        return null
    }

    const columns = getColumns(readOnly)
    const cards = getCards(readOnly)

    return (
        <ResourceContextProviderWithClearEffect
            value={{
                name: 'expirations',
                resource: resource + '/' + unit?.id + '/expirations',
            }}
        >
            <PageContent>
                <ListBase
                    sort={defaultSort}
                    preferencesName="unit-expirations"
                >
                    <ViewHeader title="Expirations">
                        <ListTotalBadge />
                        <ViewHeader.Content placement="rightMobile">
                            {readOnly ? null : (
                                <ExpirationEditor>
                                    {(open) => (
                                        <IconBox
                                            title="Set Expiration"
                                            onClick={open}
                                        >
                                            <Icons.Add />
                                        </IconBox>
                                    )}
                                </ExpirationEditor>
                            )}
                        </ViewHeader.Content>
                    </ViewHeader>
                    <List
                        columnsCfg={columns}
                        cardsCfg={cards}
                        bulkActions={bulkActions}
                        disableExportButton
                        sortCfg={sort}
                        listFTUProps={{
                            title: 'No Expirations',
                            linkText: readOnly ? (
                                ''
                            ) : (
                                <ExpirationEditor>
                                    {(open) => <Anchor onClick={open}>Set Expiration</Anchor>}
                                </ExpirationEditor>
                            ),
                            imageSrc: images.expirations,
                            linkAction: () => {
                                //
                            },
                            action: null,
                        }}
                    />
                </ListBase>
            </PageContent>
        </ResourceContextProviderWithClearEffect>
    )
}

export default UnitExpirations

const defaultSort: SortPayload<ExpirationModel> = {
    field: expirationFields.status.source,
    order: 'DESC',
}

const getColumns = (readOnly: boolean) => {
    const columns: DatagridColumnsProps<ExpirationModel> = {
        checkboxSelection: !readOnly,
        mainField: expirationFields.name.source,
        pinnedColumns: {
            right: [expirationFields.files.source],
        },
        avatarSource: expirationFields.avatar.source,
        columns: [
            datagridAvatarColumn({
                field: expirationFields.avatar.source,
                headerName: expirationFields.avatar.label,
                avatar: (record) => <expirationFields.avatar.Value status={record.status} />,
            }),
            {
                field: expirationFields.name.source,
                headerName: expirationFields.name.label,
                renderCell: ({ row }) => (
                    <ExpirationEditor
                        id={row.id}
                        readOnly={readOnly}
                    >
                        {(open) => <Anchor onClick={open}>{row.name}</Anchor>}
                    </ExpirationEditor>
                ),
            },
            {
                field: expirationFields.description.source,
                headerName: expirationFields.description.label,
            },
            {
                field: expirationFields.date.source,
                headerName: expirationFields.date.label,
                valueFormatter: ({ value }) => expirationFields.date.value(value),
            },
            {
                field: expirationFields.threshold.leftSource,
                headerName: expirationFields.threshold.leftLabel,
                renderCell: ({ row }) =>
                    expirationFields.threshold.leftValue(row.remainingTillOverduePretty),
            },
            {
                field: expirationFields.status.source,
                headerName: expirationFields.status.label,
                renderCell: ({ value }) => <expirationFields.status.Value value={value} />,
            },
            {
                field: expirationFields.files.source,
                headerName: expirationFields.files.label,
                renderCell: ({ row }) => <expirationFields.files.Value record={row} />,
            },
        ],
        actions: ({ row: data }, { children }) => [
            <EditAction
                children={children}
                id={data.id}
                readOnly={readOnly}
                key="edit"
            />,

            <CompleteAction
                children={children}
                key="complete"
                data={data}
                disabled={readOnly}
            />,
            deleteOneAction({
                children,
                id: data.id,
                disabled: readOnly,
                confirmConfig: {
                    title: 'Are you sure you want to delete this expiration?',
                },
            }),
        ],
    }

    return columns
}

const bulkActions: ListBulkActions<ExpirationModel> = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: 'Are you sure you want to delete the selected expirations?',
            },
        }),
    ]
}

const sort: ListSortContentProps<ExpirationModel> = {
    sortBy: [
        {
            id: expirationFields.name.source,
            label: expirationFields.name.label,
        },
        {
            id: expirationFields.description.source,
            label: expirationFields.description.label,
        },
        {
            id: expirationFields.date.source,
            label: expirationFields.date.label,
        },
        {
            id: expirationFields.threshold.leftSource,
            label: expirationFields.threshold.leftLabel,
        },
        {
            id: expirationFields.status.source,
            label: expirationFields.status.label,
        },
        {
            id: expirationFields.files.source,
            label: expirationFields.files.label,
        },
    ],
}

const getCards = (readOnly: boolean) => {
    const cards: CardListConfig<ExpirationModel> = {
        titleSource: (record) => (
            <ExpirationEditor
                readOnly={readOnly}
                id={record.id}
            >
                {(open) => <Anchor onClick={open}>{record.name}</Anchor>}
            </ExpirationEditor>
        ),
        avatarProps: (record) => expirationFields.avatar.getProps(record.status),
        disableTitleLink: true,
        details: [
            {
                source: expirationFields.description.source,
                label: expirationFields.description.label,
            },
            {
                source: expirationFields.date.source,
                label: expirationFields.date.label,
                render: (value) => expirationFields.date.value(value),
            },
            {
                source: expirationFields.threshold.leftSource,
                label: expirationFields.threshold.leftLabel,
                render: (_, data) =>
                    expirationFields.threshold.leftValue(data.remainingTillOverduePretty),
            },
            {
                source: expirationFields.status.source,
                label: expirationFields.status.label,
                render: (value) => <expirationFields.status.Value value={value} />,
            },
            {
                source: expirationFields.files.source,
                label: expirationFields.files.label,
                render: (_, data) => <expirationFields.files.Value record={data} />,
            },
        ],
        actions: (data, { children }) => [
            multiselectAction({ children, id: data.id, disabled: readOnly }),
            <EditAction
                children={children}
                id={data.id}
                readOnly={readOnly}
                key="edit"
            />,
            <CompleteAction
                children={children}
                key="complete"
                data={data}
                disabled={readOnly}
            />,
            deleteOneAction({
                children,
                id: data.id,
                disabled: readOnly,
                confirmConfig: {
                    title: 'Are you sure you want to delete this expiration?',
                },
            }),
        ],
    }

    return cards
}

const EditAction = ({
    id,
    readOnly,
    children,
}: {
    id: Identifier
    readOnly: boolean
    children: ActionChildren
}) => {
    return (
        <ExpirationEditor
            id={id}
            key="edit"
            readOnly={readOnly}
        >
            {(open) =>
                children({
                    onClick: open,
                    Icon: Icons.Edit,
                    title: 'Edit',
                })
            }
        </ExpirationEditor>
    )
}

const CompleteAction: FC<{
    children: ActionChildren
    disabled?: boolean
    data: ExpirationModel
}> = ({ children, disabled, data }) => {
    const confirm = useConfirm()
    const { refetch } = useListContext()
    const resource = useResourceContext()
    const create = useExpirationEditor()
    const notify = useNotify()

    const handleClick = () => {
        confirm({
            title: 'Would you like to complete this expiration?',
            confirmButtonProps: { startIcon: <Icons.CheckCircleOutline />, children: 'Complete' },
            content: (
                <BooleanInput
                    source="openNew"
                    label="Set a new expiration with the same details."
                    sx={{ [`.${formControlLabelClasses.root}`]: { mr: 0 } }}
                    defaultValue
                />
            ),
            onConfirm: async ({ formValues }) => {
                await api.post(`${resource}/${data.id}/complete`)
                refetch()
                notify({ title: 'Successfully completed!', type: 'success' })
                if (formValues.openNew) {
                    create({
                        defaultValues: {
                            name: data.name,
                            description: data.description,
                            thresholdValue: data.thresholdValue,
                            thresholdType: data.thresholdType,
                            altersUnitStatus: data.altersUnitStatus,
                        },
                    })
                }
            },
            awaitOnConfirm: true,
        })
    }

    return children({
        Icon: Icons.CheckCircleOutline,
        title: 'Complete',
        onClick: handleClick,
        disabled: disabled || data.status === expirationFields.status.Keys.COMPLETED,
    })
}
