import { type FC } from 'react'

import {
    GridColumnMenuContainer,
    type GridColumnMenuProps,
    GridColumnPinningMenuItems,
    HideGridColMenuItem,
} from '@mui/x-data-grid-premium'

const ColumnMenu: FC<GridColumnMenuProps> = (props) => {
    const { hideMenu, currentColumn, open } = props

    return (
        <GridColumnMenuContainer
            hideMenu={hideMenu}
            currentColumn={currentColumn}
            open={open}
        >
            {currentColumn.hideable ? (
                <HideGridColMenuItem
                    column={currentColumn}
                    onClick={hideMenu}
                />
            ) : null}
            {currentColumn.pinnable ? (
                <GridColumnPinningMenuItems
                    column={currentColumn}
                    onClick={hideMenu}
                />
            ) : null}
        </GridColumnMenuContainer>
    )
}

export default ColumnMenu
