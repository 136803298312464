import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import {
    type DatagridColumnsProps,
    List,
    ListAvatar,
    ListBase,
    type CardListConfig,
    type FilterConfig,
    type ListSortContentProps,
    datagridAvatarColumn,
} from 'components'
import {
    type Action,
    type AuthStore,
    deleteOneAction,
    editRedirectInListAction,
    type SortPayload,
} from 'core'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import { type InvoiceModel, invoiceFields } from 'resources/invoices'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { InvoicesListHeader } from './components'

const InvoicesList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { columnsConfig, cardsConfig, filterConfig, sortConfig } = getConfig(auth)

        return (
            <ListBase sort={defaultSort}>
                <InvoicesListHeader />
                <PageContent>
                    <List
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        filtersCfg={filterConfig}
                        sortCfg={sortConfig}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default InvoicesList

const actions: Action<InvoiceModel> = ({ id }, { children }) => {
    return [editRedirectInListAction({ children, id }), deleteOneAction({ children, id })]
}

const defaultSort: SortPayload<InvoiceModel> = {
    field: 'created',
    order: 'DESC',
}

const getConfig = (auth: AuthStore) => {
    const cardsConfig: CardListConfig<InvoiceModel> = {
        titleSource: invoiceFields.number.source,
        subTitleSource: (record) => invoiceFields.status.value(record),
        defaultImage: (record) => <invoiceFields.avatar.Icon record={record} />,
        details: [
            shopFields.self.dataCardRow({
                dataToRecord: (record: InvoiceModel) => record.shopData,
                withShopContext: auth,
            }),
            customerFields.self.dataCardRow({
                dataToRecord: (record: InvoiceModel) => record.customerData,
            }),
            unitFields.self.dataCardRow({
                dataToRecord: (record) => record.unitData,
            }),
            woFields.self.dataCardRow({
                dataToRecord: (record: InvoiceModel) => record.workOrderData,
            }),
            invoiceFields.poNumber.dataCardRow(),
            invoiceFields.invoiceDate.dataCardRow(),
            invoiceFields.closedDate.dataCardRow(),
            invoiceFields.total.dataCardRow(),
            invoiceFields.balanceDue.dataCardRow(),
        ],
        actions,
    }

    const columnsConfig: DatagridColumnsProps<InvoiceModel & { workOrderCompleted: string }> = {
        mainField: invoiceFields.number.source,
        pinnedColumns: {
            right: [invoiceFields.balanceDue.source],
        },
        columns: [
            datagridAvatarColumn({
                field: invoiceFields.avatar.source,
                headerName: invoiceFields.avatar.label,
                avatar: (record) => (
                    <ListAvatar
                        id={record.id}
                        defaultImage={<invoiceFields.avatar.Icon record={record} />}
                    />
                ),
            }),
            invoiceFields.number.tableColumn(),
            shopFields.self.tableColumn({
                dataToRecord: (record: InvoiceModel) => record.shopData,
                withShopContext: auth,
            }),
            invoiceFields.type.tableColumn(),
            customerFields.self.tableColumn({
                dataToRecord: (record: InvoiceModel) => record.customerData,
            }),
            unitFields.self.tableColumn({
                dataToRecord: (record) => record.unitData,
            }),
            woFields.self.tableColumn({
                dataToRecord: (record: InvoiceModel) => record.workOrderData,
            }),
            invoiceFields.woCompletedDate.tableColumn(),
            invoiceFields.poNumber.tableColumn(),
            invoiceFields.status.tableColumn(),
            createdField.tableColumn({
                dataToValue: (record) => record.created,
            }),
            invoiceFields.invoiceDate.tableColumn(),
            invoiceFields.dueDate.tableColumn(),
            invoiceFields.closedDate.tableColumn(),
            invoiceFields.total.tableColumn(),
            invoiceFields.paid.tableColumn(),
            invoiceFields.balanceDue.tableColumn(),
        ],
        actions: ({ row }, args) => actions(row, args),
    }

    const filterConfig: FilterConfig<InvoiceModel & { workOrderCompleted: string }> = {
        filters: [
            shopFields.self.filter({ withShopContext: auth }),
            invoiceFields.type.filter(),
            customerFields.self.filter(),
            unitFields.self.filter(),
            woFields.self.filter(),
            invoiceFields.woCompletedDate.filter(),
            invoiceFields.status.filter(),
            createdField.filter(),
            invoiceFields.invoiceDate.filter(),
            invoiceFields.closedDate.filter(),
            invoiceFields.total.filter(),
        ],
    }

    const sortConfig: ListSortContentProps<InvoiceModel & { workOrderCompleted: string }> = {
        sortBy: [
            invoiceFields.number.sort(),
            shopFields.self.sort({ withShopContext: auth }),
            invoiceFields.type.sort(),
            customerFields.self.sort(),
            unitFields.self.sort(),
            woFields.self.sort(),
            invoiceFields.woCompletedDate.sort(),
            invoiceFields.poNumber.sort(),
            invoiceFields.status.sort(),
            createdField.sort(),
            invoiceFields.invoiceDate.sort(),
            invoiceFields.closedDate.sort(),
            invoiceFields.total.sort(),
            invoiceFields.paid.sort(),
            invoiceFields.balanceDue.sort(),
        ],
    }

    return {
        sortConfig,
        filterConfig,
        columnsConfig,
        cardsConfig,
    }
}
