import { useState } from 'react'

import { useResourcePreferences } from 'core/context'

interface Props {
    columns: { field: string }[]
}

const useOrdering = ({ columns }: Props) => {
    const preferences = useResourcePreferences()
    const [order, setOrder] = useState<string[]>(
        () => preferences.value.order || columns.map((col) => col.field),
    )

    const updateOrder = (fields: string[]) => {
        setOrder(fields)
        preferences.updateLocal('order', fields)
        preferences.syncLocal()
    }

    return { order, updateOrder }
}

export default useOrdering
