import { createContext, useContext, type FC } from 'react'

import { type GridEnrichedColDef, type GridColDef } from '@mui/x-data-grid'

import { type DataRecord, type WithChildrenNode } from 'appTypes'

import { type DatagridColumnsProps, type PinnedColumns } from '../types'

import useColumns from './useColumns'

export interface ColumnsContextValue {
    columns: GridEnrichedColDef<DataRecord>[]
    visibilityModel: { [key: string]: boolean }
    setVisibilityModel: (model: { [key: string]: boolean }) => void
    setColumnVisibility: (field: string, visible: boolean) => void
    setSize: (col: GridColDef) => void
    pinnedColumns: PinnedColumns
    setPinnedColumns: (pinnedColumns: PinnedColumns) => void
    updateOrder: (fields: string[]) => void
}

const ColumnsContext = createContext<ColumnsContextValue>(null)

export interface ColumnsProps extends WithChildrenNode {
    columnsConfig: DatagridColumnsProps
}

export const ColumnsProvider: FC<ColumnsProps> = ({ children, columnsConfig }) => {
    const value = useColumns(columnsConfig)

    return <ColumnsContext.Provider value={value}>{children}</ColumnsContext.Provider>
}

export const useColumnsContext = () => useContext(ColumnsContext)
