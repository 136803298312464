import { formatMoney, type CardListConfig, type DatagridColumnsProps } from 'components'
import { type ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { type FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterValueInput from 'components/list/filter/ListFilterValueInput'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getListData } from 'core/data'
import {
    type CK14Aspects,
    ck14Aspects,
    ck14Resource,
    type CK14Aggregates,
    type CK14Model,
    ck14Fields,
} from 'resources/vmrs'
import { woFields, JobItemTypeKeys } from 'resources/workOrders'
import { PageContent } from 'ui'

import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'

const aspects = [ck14Aspects.totals] as CK14Aspects[]

interface CK14ReportsModel extends CK14Model<typeof aspects> {}

const defaultSort: SortPayload<CK14ReportsModel> = {
    field: 'total',
    order: 'DESC',
}

const cardsCfg: CardListConfig<CK14ReportsModel> = {
    titleSource: ck14Fields.self.value,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        { source: 'type', label: 'Repair Type' },
        woFields.total.dataCardRow(JobItemTypeKeys.PART),
        woFields.total.dataCardRow(JobItemTypeKeys.LABOR),
        woFields.total.dataCardRow(JobItemTypeKeys.SERVICE),
        woFields.total.dataCardRow(JobItemTypeKeys.FEE),
        woFields.total.dataCardRow(JobItemTypeKeys.TAX),
        woFields.total.dataCardRow(),
    ],
}
const columnsCfg: DatagridColumnsProps<CK14ReportsModel> = {
    checkboxSelection: false,
    constantColumns: {
        type: true,
    },
    mainField: 'codeDescription',
    pinnedColumns: {
        right: [woFields.total.getConfig().source],
    },
    columns: [
        {
            field: 'codeDescription',
            headerName: 'Reason for Repair',
        },
        {
            field: 'type',
            headerName: 'Repair Type',
        },
        woFields.total.tableColumn(JobItemTypeKeys.PART),
        woFields.total.tableColumn(JobItemTypeKeys.LABOR),
        woFields.total.tableColumn(JobItemTypeKeys.SERVICE),
        woFields.total.tableColumn(JobItemTypeKeys.FEE),
        woFields.total.tableColumn(JobItemTypeKeys.TAX),
        woFields.total.tableColumn(),
    ],
    actions: null,
}
const filtersCfg: FilterConfig<CK14ReportsModel> = {
    filters: [
        {
            id: 'codeDescription',
            label: 'Reason for Repair',
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    withOperator
                />
            ),
        },
        {
            id: 'type',
            label: 'Repair Type',
        },
        woFields.total.filter(JobItemTypeKeys.PART),
        woFields.total.filter(JobItemTypeKeys.LABOR),
        woFields.total.filter(JobItemTypeKeys.SERVICE),
        woFields.total.filter(JobItemTypeKeys.FEE),
        woFields.total.filter(JobItemTypeKeys.TAX),
        woFields.total.filter(),
    ],
}
const sortCfg: ListSortContentProps<CK14ReportsModel> = {
    sortBy: [
        { id: 'codeDescription', label: 'Reason for Repair' },
        { id: 'type', label: 'Repair Type' },
        woFields.total.sort(JobItemTypeKeys.PART),
        woFields.total.sort(JobItemTypeKeys.LABOR),
        woFields.total.sort(JobItemTypeKeys.SERVICE),
        woFields.total.sort(JobItemTypeKeys.FEE),
        woFields.total.sort(JobItemTypeKeys.TAX),
        woFields.total.sort(),
    ],
}
const CostPerReasonForRepairCK14 = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck14Resource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: aspects }}
            >
                <ReportsHeader<CK14ReportsModel>
                    renderTotal={(list) => {
                        const listData = getListData<CK14Aggregates>(list)
                        return (
                            <>
                                {list.total} | {formatMoney(listData.total || 0)} total
                            </>
                        )
                    }}
                >
                    Cost per Reason for Repair
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="cost-per-reason-for-repair"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerReasonForRepairCK14
