import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtLaborResource, type PtLaborModel } from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { LaborList, ListHeader } from './components'

const PricingTiersLabors = () => {
    const record = useRecordContext<ShopModel>()

    return (
        <ResourceContextProviderWithClearEffect value={getPtLaborResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
            >
                <ListHeader />
                <LaborList />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default PricingTiersLabors

const defaultSort: SortPayload<PtLaborModel> = {
    field: 'created',
    order: Order.DESC,
}
