import { type ReactElement } from 'react'

import { type DataGridProps } from '@mui/x-data-grid'
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    gridClasses,
    type GridSlotsComponent,
} from '@mui/x-data-grid-premium'

import { type DataRecord } from 'appTypes'
import { type SxProps } from 'lib'
import { BoxContainer, Skeleton } from 'ui'

import { type MassColProps, type Column, type ExportColumnType, type ColumnMeta } from './types'

export const actionsField = 'actions'
export const checkboxField = GRID_CHECKBOX_SELECTION_COL_DEF.field

export const getVisibleColumns = (columns: Column[]) => {
    return columns.reduce((cols, col) => {
        const appendColumn = (column: { field: string; headerName?: string }) => {
            cols.push([column.field, column.headerName || column.field])
        }
        if (col.toExport) {
            if (col.toExport.separate) {
                col.toExport.separate.forEach(appendColumn)
            }
        } else {
            appendColumn(col as any)
        }
        return cols
    }, [] as ExportColumnType[])
}

export { gridClasses as datagridClasses } from '@mui/x-data-grid-premium'

export const defaultMassColProps: MassColProps = {
    sortable: false,
    filterable: false,
    groupable: false,
    hideable: true,
    resizable: true,
    width: 200,
}

export const loadingSx: SxProps = {
    [`& .${gridClasses.virtualScroller}`]: {
        marginTop: '0px !important',
    },
    [`& .${gridClasses.columnHeaders}`]: {
        display: 'none',
    },
    [`& .${gridClasses.virtualScrollerContent}`]: {
        padding: '26px 0px',
    },
    [`& .${gridClasses.virtualScrollerRenderZone}`]: {
        width: '100%',
        height: '100%',
    },
}

interface AvatarColumnParams<RecordType extends DataRecord> {
    field: keyof RecordType
    headerName?: string
    avatar: (record: RecordType) => ReactElement
}

export const datagridAvatarColumn = <RecordType extends DataRecord>({
    field,
    headerName = 'Avatar',
    avatar,
}: AvatarColumnParams<RecordType>): Column<RecordType> => {
    return {
        field,
        headerName,
        width: 72,
        renderCell: (cell) => avatar(cell.row),
        resizable: false,
    }
}

export const columnMinWidth = 90

const skeletonComponent: Partial<GridSlotsComponent> = {
    Row: () => (
        <BoxContainer
            width="100%"
            height="56px"
            padding="0px 16px"
        >
            {new Array(5).fill(0).map((v, i) => {
                return (
                    <Skeleton
                        sx={{
                            flex: i === 0 ? '1' : '4',
                            height: '8px',
                        }}
                        key={i}
                    />
                )
            })}
        </BoxContainer>
    ),
    Footer: () => null,
}
export const skeletonProps: Partial<DataGridProps> = {
    rows: new Array(10).fill(null).map((v, i) => {
        return { id: `${i}` }
    }),
    rowCount: 10,
    components: skeletonComponent,
}

export const columnGetMeta = (column: Column): ColumnMeta => {
    // @ts-ignore
    return column.meta || {}
}

export const columnSetMeta = (column: Column, meta: ColumnMeta): void => {
    // @ts-ignore
    column.meta = meta
}
