import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    type ListSortContentProps,
} from 'components'
import { deleteOneAction } from 'core/actions'
import { createdField } from 'resources/base'
import { PTFeeDrawerToggler, ptFeesFields, type PtFeeModel } from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'
import { Typography } from 'ui'

import { deleteFeeActionParams } from '../utils'

const columnsCfg: DatagridColumnsProps<PtFeeModel> = {
    checkboxSelection: false,
    mainField: ptFeesFields.name.source,
    columns: [
        {
            field: ptFeesFields.name.source,
            headerName: ptFeesFields.name.label,
            renderCell: ({ row }) => <ptFeesFields.name.Value record={row} />,
        },
        {
            field: ptFeesFields.taxable.source,
            headerName: ptFeesFields.taxable.label,
            renderCell: ({ row }) => ptFeesFields.taxable.value(row),
        },
        {
            field: ptFeesFields.flatFee.source,
            headerName: ptFeesFields.flatFee.label,
            renderCell: ({ row }) => ptFeesFields.flatFee.value(row),
            align: 'right',
        },
        {
            field: ptFeesFields.percentage.source,
            headerName: ptFeesFields.percentage.label,
            renderCell: ({ row }) => ptFeesFields.percentage.value(row),
        },
        {
            field: ptFeesFields.calculateOnParts.source,
            headerName: ptFeesFields.calculateOnParts.label,
            renderCell: ({ row }) => ptFeesFields.calculateOnParts.value(row),
        },
        {
            field: ptFeesFields.calculateOnLabor.source,
            headerName: ptFeesFields.calculateOnLabor.label,
            renderCell: ({ row }) => ptFeesFields.calculateOnLabor.value(row),
        },
        {
            field: ptFeesFields.calculateOnServices.source,
            headerName: ptFeesFields.calculateOnServices.label,
            renderCell: ({ row }) => ptFeesFields.calculateOnServices.value(row),
        },
        {
            field: ptFeesFields.minAmount.source,
            headerName: ptFeesFields.minAmount.label,
            renderCell: ({ row }) => ptFeesFields.minAmount.value(row),
            align: 'right',
        },
        {
            field: ptFeesFields.maxAmount.source,
            headerName: ptFeesFields.maxAmount.label,
            renderCell: ({ row }) => ptFeesFields.maxAmount.value(row),
            align: 'right',
        },
    ],
    actions: ({ row }, { children }) => [
        <PTFeeDrawerToggler
            id={row.id}
            key="edit"
        >
            {(open) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick: open,
                })
            }
        </PTFeeDrawerToggler>,
        deleteOneAction({
            children,
            id: row.id,
            ...deleteFeeActionParams,
        }),
    ],
}

const cardsCfg: CardListConfig<PtFeeModel> = {
    titleSource: (record) => <ptFeesFields.name.Value record={record} />,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        {
            source: ptFeesFields.taxable.source,
            label: ptFeesFields.taxable.label,
            render: (_, record) => ptFeesFields.taxable.value(record),
        },
        {
            source: ptFeesFields.flatFee.source,
            label: ptFeesFields.flatFee.label,
            render: (_, record) => ptFeesFields.flatFee.value(record),
        },
        {
            source: ptFeesFields.percentage.source,
            label: ptFeesFields.percentage.label,
            render: (_, record) => ptFeesFields.percentage.value(record),
        },

        {
            source: ptFeesFields.calculateOnParts.source,
            label: ptFeesFields.calculateOnParts.label,
            render: (_, record) => ptFeesFields.calculateOnParts.value(record),
        },
        {
            source: ptFeesFields.calculateOnLabor.source,
            label: ptFeesFields.calculateOnLabor.label,
            render: (_, record) => ptFeesFields.calculateOnLabor.value(record),
        },
        {
            source: ptFeesFields.calculateOnServices.source,
            label: ptFeesFields.calculateOnServices.label,
            render: (_, record) => ptFeesFields.calculateOnServices.value(record),
        },
        {
            source: ptFeesFields.minAmount.source,
            label: ptFeesFields.minAmount.label,
            render: (_, record) => ptFeesFields.minAmount.value(record),
        },
        {
            source: ptFeesFields.maxAmount.source,
            label: ptFeesFields.maxAmount.label,
            render: (_, record) => ptFeesFields.maxAmount.value(record),
        },
    ],
    actions: (record, { children }) => [
        <PTFeeDrawerToggler
            id={record.id}
            key="edit"
        >
            {(open) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick: open,
                })
            }
        </PTFeeDrawerToggler>,
        deleteOneAction({
            children,
            id: record.id,
            ...deleteFeeActionParams,
        }),
    ],
}

const sortCfg: ListSortContentProps<PtFeeModel> = {
    sortBy: [
        createdField.sort(),
        {
            id: ptFeesFields.name.source,
            label: ptFeesFields.name.label,
        },
        {
            id: ptFeesFields.taxable.source,
            label: ptFeesFields.taxable.label,
        },
        {
            id: ptFeesFields.flatFee.source,
            label: ptFeesFields.flatFee.label,
        },
        {
            id: ptFeesFields.percentage.source,
            label: ptFeesFields.percentage.label,
        },
        {
            id: ptFeesFields.calculateOnParts.source,
            label: ptFeesFields.calculateOnParts.label,
        },
        {
            id: ptFeesFields.calculateOnLabor.source,
            label: ptFeesFields.calculateOnLabor.label,
        },
        {
            id: ptFeesFields.calculateOnServices.source,
            label: ptFeesFields.calculateOnServices.label,
        },
        {
            id: ptFeesFields.minAmount.source,
            label: ptFeesFields.minAmount.label,
        },
        {
            id: ptFeesFields.maxAmount.source,
            label: ptFeesFields.maxAmount.label,
        },
    ],
}
const FeeList = () => {
    const record = useRecordContext<ShopModel>()

    return (
        <List
            isLoading={!record}
            columnsCfg={columnsCfg}
            cardsCfg={cardsCfg}
            sortCfg={sortCfg}
            disableExportButton
            disableManageColumns
            listFTUProps={{
                imageSrc: images.noLineItems,
                title: 'No Items Added',
                linkText: (
                    <PTFeeDrawerToggler>
                        {(open) => (
                            <Typography
                                variant="body1"
                                onClick={open}
                                color={(theme) => theme.palette.primary.main}
                                sx={{ cursor: 'pointer' }}
                            >
                                Add Item
                            </Typography>
                        )}
                    </PTFeeDrawerToggler>
                ),
                linkAction: (e) => {
                    e.preventDefault()
                },
            }}
        />
    )
}

export default FeeList
