import { type NoInfer } from 'appTypes'
import {
    ListFilterRangeInput,
    type Column,
    type ListFilterChoice,
    type DataListDetails,
    formatMoney,
    costMaskParams,
} from 'components'

import { type AssignmentModel } from '../types'

const source = 'accumulatedCost' satisfies keyof AssignmentModel

const label = 'Accumulated Cost'

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: ({ value }) => formatMoney(value),
        align: 'right',
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (value) => formatMoney(value),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => (
            <ListFilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies ListFilterChoice

export const accumulatedCost = {
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
