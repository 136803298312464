import { type ReactElement, useEffect, useRef, type FC, useState } from 'react'

import { useEventCallback } from '@mui/material'
import { gridClasses, DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { useListContext } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { ListViewMode } from 'components/list/ListViewContext'
import useFirstListLoad from 'components/list/useFirstLoad'
import { useResourcePreferences } from 'core/context'
import { alpha, classes, globalClassNames } from 'lib'
import { typographyClasses } from 'ui'
import { findClosestParent } from 'utils'

import Pagination from '../list/Pagination'

import ColumnMenu from './ColumnMenu'
import { useColumnsContext } from './controls'
import { type DatagridProps } from './types'
import { actionsField, checkboxField, datagridClasses, loadingSx, skeletonProps } from './utils'

const Datagrid: FC<DatagridProps> = <RecordType extends DataRecord = any>({
    checkboxSelection = true,
    disableSelectRecord,
    loading,
    hideFooter = false,
    disableColumnReorder = false,
}: DatagridProps<RecordType>) => {
    const listContext = useListContext()
    const { data, onSelect, selectedIds, page, setPage, perPage, setPerPage, total } = listContext

    const firstLoad = useFirstListLoad(listContext, ListViewMode.list, loading)
    const preferences = useResourcePreferences()

    const movedColumn = useRef<{ field: string; index: number } | null>(null)

    const {
        columns,
        visibilityModel,
        updateOrder,
        setPinnedColumns,
        pinnedColumns,
        setSize,
        setVisibilityModel,
    } = useColumnsContext()

    const apiRef = useGridApiRef()

    if (!columns) {
        return null
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }
    const handleRowsChange = (event: React.ChangeEvent<unknown>, callback) => {
        preferences.update({
            perPage: Number(callback.props.value),
        })
        setPerPage(callback.props.value)
    }
    const { rows: fakeData, ...skeletonGridProps } = firstLoad ? skeletonProps : { rows: [] }

    return (
        <Container
            isLoading={firstLoad}
            onDrop={() => {
                if (!movedColumn.current) {
                    return
                }
                const fields = apiRef.current.state.columns.all.filter(
                    (field) => field !== actionsField && field !== checkboxField,
                )

                updateOrder(fields)

                movedColumn.current = null
            }}
            rowsLength={(data ? data : fakeData).length}
            hideFooter={hideFooter}
        >
            <DataGridPremium
                apiRef={apiRef}
                disableSelectionOnClick
                onColumnOrderChange={(params) => {
                    movedColumn.current = {
                        field: params.field,
                        index: params.targetIndex,
                    }
                }}
                // autoHeight
                rowHeight={56}
                hideFooterSelectedRowCount
                components={{
                    Pagination,
                    ColumnMenu,
                }}
                pagination
                disableColumnReorder={disableColumnReorder}
                hideFooter={hideFooter}
                isRowSelectable={
                    disableSelectRecord ? ({ row }) => !disableSelectRecord(row) : undefined
                }
                pinnedColumns={firstLoad ? emptyObject : pinnedColumns}
                onPinnedColumnsChange={setPinnedColumns}
                onColumnWidthChange={({ colDef }) => {
                    setSize(colDef)
                }}
                onColumnVisibilityModelChange={setVisibilityModel}
                componentsProps={{
                    pagination: {
                        count: total,
                        component: 'div',
                        page: page - 1,
                        onPageChange: handlePageChange,
                        rowsPerPage: perPage,
                        rowsPerPageOptions: [5, 10, 25, 50, 100],
                        labelDisplayedRows: () =>
                            `${page * perPage - perPage + 1}-\
                        ${total < page * perPage ? total : page * perPage} of ${total}`,
                        onRowsPerPageChange: handleRowsChange,
                    },
                    columnMenu: {
                        hideable: true,
                    },
                }}
                sx={{
                    flexGrow: 0,
                    width: '100%',
                    bgcolor: 'white',
                    color: 'text.main',
                    [`& .${gridClasses.checkboxInput}`]: {
                        padding: '0px !important',
                    },
                    [`& .${gridClasses.cell}`]: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        '&:focus-within': {
                            outline: 'none',
                        },
                        [`& .${typographyClasses.root}`]: {
                            whiteSpace: 'inherit',
                            overflow: 'inherit',
                            textOverflow: 'inherit',
                        },
                    },
                    [`& .${gridClasses.cellContent}`]: {
                        width: '100%',
                        '&:empty::before': {
                            content: '"-"',
                            display: 'inline-block',
                        },
                    },
                    [`& .${gridClasses.columnHeader}.${gridClasses['columnHeader--sortable']}`]: {
                        padding: '16px',
                    },
                    [`& .${gridClasses.columnHeader}:focus-within`]: {
                        outline: 'none',
                    },
                    [`& 	.${gridClasses.footerContainer}`]: {
                        justifyContent: 'flex-end',
                    },
                    [`& .${classes.selected}`]: (theme) => ({
                        backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`,
                    }),
                    ...(firstLoad ? loadingSx : {}),
                    [`& .MuiSkeleton-root`]: {
                        marginLeft: '16px',
                        marginRight: '16px',
                    },
                }}
                rows={data ? data : fakeData}
                rowCount={total}
                columns={columns}
                columnVisibilityModel={visibilityModel}
                paginationMode="server"
                rowsPerPageOptions={[1, 5, 10, 25, 100]}
                pageSize={perPage}
                checkboxSelection={checkboxSelection}
                selectionModel={checkboxSelection ? selectedIds : defaultSelectionModel}
                onSelectionModelChange={(selectionModel) => {
                    try {
                        onSelect(selectionModel)
                    } catch {
                        // sometimes this throws an error when selectedIds change during render
                    }
                }}
                localeText={{
                    toolbarColumns: 'MANAGE COLUMNS',
                    checkboxSelectionHeaderName: 'Checkbox Selection',
                }}
                {...(firstLoad ? skeletonGridProps : {})}
            />
        </Container>
    )
}

export default Datagrid

const defaultSelectionModel = []

export const datagridEmptyCell = <div className={globalClassNames.displayNone}>-</div>

const emptyObject = {}

interface ContainerProps {
    onDrop: () => void
    isLoading: boolean
    children: ReactElement
    rowsLength: number
    hideFooter: boolean
}

const Container: FC<ContainerProps> = ({
    children,
    isLoading,
    onDrop: onDropProp,
    rowsLength,
    hideFooter,
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerHeight, setH] = useState<number>()
    const onDrop = useEventCallback(onDropProp)

    useEffect(() => {
        if (isLoading) {
            return
        }

        // onColumnOrderChange executes on each column movement. We want to execute it only when it stops
        // use drop instead of dragend, because dragend fires on cancel as well
        const drop = (event: Event) => {
            const target = event.target as HTMLElement
            if (
                !target ||
                !findClosestParent(target, (element) =>
                    element.classList.contains(datagridClasses.columnHeaderTitleContainer),
                ) ||
                target.classList.contains(datagridClasses.cell)
            ) {
                return
            }

            onDrop()
        }

        const el = containerRef.current

        const onResize = () => {
            const height = getHeightWithoutPadding(el)
            setH(height)
        }

        const resizeObserver = new ResizeObserver(onResize)
        resizeObserver.observe(el)

        el.addEventListener('drop', drop)

        return () => {
            el.removeEventListener('drop', drop)
            resizeObserver.disconnect()
        }
    }, [isLoading])

    const footerH = hideFooter ? 0 : 53
    const outerHeight = footerH + rowHeight + 20
    const minH = 10 * rowHeight + outerHeight
    const contentHeight = rowsLength * rowHeight
    const calculatedHeight = contentHeight + outerHeight

    return (
        <div
            ref={containerRef}
            style={{ height: '1px', flexGrow: 1 }}
        >
            <div style={{ height: '100%', ...getHeight(minH, calculatedHeight, containerHeight) }}>
                {children}
            </div>
        </div>
    )
}

const getHeight = (minH: number, contentHeight: number, containerHeight: number) => {
    const max = Math.max(minH, containerHeight || 100)
    if (max > contentHeight) {
        return { maxHeight: contentHeight }
    }

    return { minHeight: max }
}

const rowHeight = 56

function getHeightWithoutPadding(element: HTMLElement) {
    const style = window.getComputedStyle(element)
    const clientHeight = element.scrollHeight
    const paddingTop = parseFloat(style.paddingTop)
    const paddingBottom = parseFloat(style.paddingBottom)
    return clientHeight - paddingTop - paddingBottom
}
