import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    LinkArrowButton,
    ListFilterValueInput,
    type ListSortContentProps,
    formatMoney,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { type AuthStore } from 'core/auth'
import { getListData } from 'core/data'
import { vendorFilters } from 'pages/Vendors/List/VendorsList'
import { addressFields } from 'resources/address'
import { countryFields } from 'resources/country'
import { stateFields } from 'resources/state'
import {
    type VendorAspects,
    type VendorAggregates,
    type VendorModel as BaseVendorModel,
    vendorAspects,
    vendorsResource,
    formatVendorTypes,
    formatVendorType,
    type VendorType,
} from 'resources/vendors'
import { JobItemTypeKeys, woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'
import { reportCostCenterFilter, reportDivisionFilter } from '../utils'

const aspects = [vendorAspects.totals] satisfies VendorAspects[]

interface VendorModel extends BaseVendorModel<typeof aspects> {}

const defaultSort: SortPayload<VendorModel> = {
    field: 'total',
    order: 'DESC',
}

const sortCfg: ListSortContentProps<VendorModel> = {
    sortBy: [
        { id: 'name', label: 'Vendor Name' },
        woFields.total.sort(JobItemTypeKeys.PART),
        woFields.total.sort(JobItemTypeKeys.LABOR),
        woFields.total.sort(JobItemTypeKeys.SERVICE),
        woFields.total.sort(JobItemTypeKeys.FEE),
        woFields.total.sort(JobItemTypeKeys.TAX),
        woFields.total.sort({ label: 'Total Expenses' }),
    ],
}

const navigateTo = (id: Identifier) => `${urls.vendors}/${id}`

const cardsCfg: CardListConfig<VendorModel> = {
    titleSource: 'name',
    titleLink: (record) => navigateTo(record.id),
    defaultImage: null,
    action: (record) => <LinkArrowButton path={navigateTo(record.id)} />,
    details: [
        {
            source: 'type',
            label: 'Vendor Type',
            render: formatVendorTypes,
        },
        woFields.total.dataCardRow(JobItemTypeKeys.PART),
        woFields.total.dataCardRow(JobItemTypeKeys.LABOR),
        woFields.total.dataCardRow(JobItemTypeKeys.SERVICE),
        woFields.total.dataCardRow(JobItemTypeKeys.FEE),
        woFields.total.dataCardRow(JobItemTypeKeys.TAX),
        woFields.total.dataCardRow({ label: 'Total Expenses' }),
    ],
}

const columnsCfg: DatagridColumnsProps<VendorModel> = {
    resetColumns: {
        [addressFields.city.source]: false,
        [addressFields.zipCode.source]: false,
        [stateFields.self.source]: false,
        [countryFields.self.source]: false,
    },
    mainField: 'name',
    pinnedColumns: {
        right: [woFields.total.getConfig().source],
    },
    checkboxSelection: false,
    columns: [
        {
            field: 'name',
            headerName: 'Vendor Name',
            renderCell: (params) => {
                return <DatagridLink {...params} />
            },
        },
        {
            field: 'type',
            headerName: 'Vendor Type',
            valueFormatter: ({ value }) => formatVendorTypes(value),
        },
        woFields.total.tableColumn(JobItemTypeKeys.PART),
        woFields.total.tableColumn(JobItemTypeKeys.LABOR),
        woFields.total.tableColumn(JobItemTypeKeys.SERVICE),
        woFields.total.tableColumn(JobItemTypeKeys.FEE),
        woFields.total.tableColumn(JobItemTypeKeys.TAX),
        woFields.total.tableColumn({ label: 'Total Expenses' }),
        countryFields.self.tableColumn({
            dataToRecord: (record: VendorModel) => record.countryData,
        }),
        stateFields.self.tableColumn({ dataToRecord: (record: VendorModel) => record.stateData }),
        addressFields.city.tableColumn({}),
        addressFields.zipCode.tableColumn({}),
    ],
    actions: null,
}

const VendorExpenses: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const filtersCfg: FilterConfig<VendorModel> = {
            filters: [
                { id: 'name', label: 'Vendor Name' },
                {
                    id: 'type',
                    label: 'Vendor Type',
                    renderComponent: (props) => (
                        <ListFilterValueInput
                            {...props}
                            makeItemLabel={(option) => formatVendorType(option.id as VendorType)}
                        />
                    ),
                },
                vendorFilters.state,
                addressFields.city.filter({}),
                addressFields.zipCode.filter({}),
                vendorFilters.paymentMethod,
                vendorFilters.paymentTerm,
                vendorFilters.tags,
                woFields.total.filter(JobItemTypeKeys.PART),
                woFields.total.filter(JobItemTypeKeys.LABOR),
                woFields.total.filter(JobItemTypeKeys.SERVICE),
                woFields.total.filter(JobItemTypeKeys.FEE),
                woFields.total.filter(JobItemTypeKeys.TAX),
                woFields.total.filter({ label: 'Total Expenses' }),
                reportCostCenterFilter(auth),
                reportDivisionFilter(auth),
            ],
        }

        return (
            <ResourceContextProviderWithClearEffect value={vendorsResource}>
                <ListBaseWithPeriod
                    preferencesName="reports-vendor-expenses"
                    sort={defaultSort}
                    filter={{ withAspects: aspects }}
                >
                    <ReportsHeader<VendorModel>
                        renderTotal={(list) => {
                            const listData = getListData<VendorAggregates>(list)
                            return (
                                <>
                                    {list.total} | {formatMoney(listData.total || 0)} total
                                </>
                            )
                        }}
                    >
                        Total Vendor Expenses
                    </ReportsHeader>
                    <PageContent>
                        <ListWithPeriodSelector
                            exportFileName="total-vendor-expenses"
                            sortCfg={sortCfg}
                            cardsCfg={cardsCfg}
                            columnsCfg={columnsCfg}
                            filtersCfg={filtersCfg}
                        />
                    </PageContent>
                </ListBaseWithPeriod>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default VendorExpenses
