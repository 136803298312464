import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import images from 'assets/images'
import {
    List,
    ViewHeader,
    ListBase,
    type DatagridColumnsProps,
    type CardListConfig,
    ListAvatar,
    ListTotalBadge,
    CardCollapsibleContent,
    PhotosCollapsibleIconHolder,
    PhotosCollapsibleContent,
    type ListSortContentProps,
    formatMoney,
    ResourceLinkButton,
} from 'components'
import {
    type AuthStore,
    type DeleteOneParams,
    deleteOneAction,
    useCreateResourcePath,
    ResourceContextProviderWithClearEffect,
    type ResourceType,
    type SortPayload,
} from 'core'
import { useTheme, formatDate, globalClassNames } from 'lib'
import { useInventoryContext } from 'resources/inventory'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import { type PartModel, partCk33Label, partFields, partsResource } from 'resources/parts'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder, TagsField } from 'resources/tags'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { Box, PageContent, Typography, BoxContainer, Tooltip } from 'ui'

import { AddReferenceButton, DeleteCardButton } from './components'

const removeReferenceProps: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to remove this cross-reference?',
        content: 'This action will also remove the cross-reference link reciprocally.',
        confirmButtonProps: { children: 'Remove' },
    },
}
const defaultPartUsageSort: SortPayload<PartModel> = {
    field: 'created',
    order: 'DESC',
}

export const getCrossReferenceCardsCfg = (
    navigateTo: (record: PartModel) => string,
    auth: any,
    inventoryView: boolean,
    resource?: ResourceType,
): CardListConfig<PartModel> => {
    return {
        titleSource: 'number',
        subTitleSource: 'description',
        imageSource: 'image',
        defaultImage: <partFields.avatar.Icon />,
        titleLink: (record) => navigateTo(record),
        details: [
            {
                source: 'componentData',
                label: partCk33Label(auth),
                render: ck33Fields.self.value,
            },
            {
                source: 'manufacturerData',
                label: 'Manufacturer',
                render: ck34Fields.self.value,
            },
            uomFields.self.dataCardRow({
                dataToRecord: (data: PartModel) => data.unitOfMeasureData,
            }),
            auth.companySettings.hasInventory
                ? null
                : {
                      source: 'cost',
                      label: 'Last Known Cost',
                      render: (value) => formatMoney(value),
                  },
        ].filter(Boolean) as CardListConfig<PartModel>['details'],
        collapsibleContent: (record) => (
            <CardCollapsibleContent
                content={[
                    {
                        iconHolder: (
                            <TagsCollapsibleIconHolder
                                tags={record.tagsData}
                                key="tagsIcon"
                            />
                        ),
                        component: (
                            <TagsCollapsibleContent
                                tags={record.tagsData}
                                key="tagsContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <NotesCollapsibleIconHolder
                                notes={record.notes}
                                key="notesIcon"
                            />
                        ),
                        component: (
                            <NotesCollapsibleContent
                                notes={record.notes}
                                key="notesContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <PhotosCollapsibleIconHolder
                                record={record}
                                key="photosIcon"
                            />
                        ),
                        component: (
                            <PhotosCollapsibleContent
                                record={record}
                                key="photosContent"
                            />
                        ),
                    },
                ]}
            />
        ),
        action: inventoryView
            ? undefined
            : (row) =>
                  deleteOneAction({
                      notifyMessage: 'Successfully removed.',
                      id: row.id,
                      resource,
                      children: ({ onClick }) => <DeleteCardButton onClick={onClick} />,
                      ...removeReferenceProps,
                  }),
    }
}

const CrossReference = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const createPath = useCreateResourcePath()
        const record = useRecordContext<PartModel>()
        const inventoryView = useInventoryContext()
        const theme = useTheme()
        if (!record) {
            return null
        }
        const navigateTo = (record: PartModel) =>
            createPath({
                resource: partsResource.resource,
                id: record.id,
                type: 'edit',
            })

        const sortCfg: ListSortContentProps<PartModel> = {
            sortBy: [
                { id: 'created', label: 'Created on' },
                { id: 'number', label: 'Part Number' },
                { id: 'description', label: 'Description' },
                { id: 'component', label: partCk33Label(auth) },
                { id: 'manufacturer', label: 'Manufacturer' },
                {
                    id: 'manufacturerPartNumber',
                    label: 'Manufacturer Part number',
                },
                auth.companySettings.hasInventory ? null : { id: 'cost', label: 'Last Known Cost' },
                uomFields.self.sort(),
            ].filter(Boolean) as ListSortContentProps<PartModel>['sortBy'],
        }

        const columnsCfg: DatagridColumnsProps<PartModel> = {
            checkboxSelection: false,
            mainField: 'number',
            columns: [
                {
                    field: 'image',
                    headerName: 'Picture',
                    maxWidth: 72,
                    renderCell: ({ row }) => (
                        <ListAvatar
                            id={row.id}
                            imageSrc={row.image}
                            color={theme.palette.primary.main}
                            defaultImage={<partFields.avatar.Icon />}
                            customPath={{
                                resource: partsResource.resource,
                                type: 'edit',
                                id: row.id,
                            }}
                        />
                    ),
                },
                {
                    field: 'number',
                    headerName: 'Part Number',
                    renderCell: ({ row }) => (
                        <ResourceLinkButton
                            to={{
                                resource: partsResource.resource,
                                type: 'edit',
                                id: row.id,
                            }}
                        >
                            {row.number}
                        </ResourceLinkButton>
                    ),
                },
                {
                    field: 'description',
                    headerName: 'Description',
                    renderCell: ({ row }) =>
                        row.description ? (
                            <Tooltip title={row.description}>
                                <Box className={globalClassNames.ellipsis}>{row.description}</Box>
                            </Tooltip>
                        ) : (
                            row.description
                        ),
                },
                {
                    field: 'componentData',
                    headerName: partCk33Label(auth),
                    valueGetter: ({ row }) => {
                        return ck33Fields.self.value((row as PartModel).componentData)
                    },
                },
                {
                    field: 'manufacturerData',
                    headerName: 'Manufacturer',
                    valueGetter: ({ row }) =>
                        ck34Fields.self.value((row as PartModel).manufacturerData),
                },
                {
                    field: 'manufacturerPartNumber',
                    headerName: 'Manufacturer Part number',
                    valueGetter: ({ row }) => row.manufacturerPartNumber,
                },
                {
                    field: 'created',
                    headerName: 'Created on',
                    renderCell: (cell) => {
                        return formatDate(
                            cell.value,
                            (dateFormats) => dateFormats.shortenedDateTime,
                        )
                    },
                },
                {
                    field: 'tagsData',
                    headerName: 'Tags',
                    renderCell: ({ row }) => <TagsField tags={row.tagsData} />,
                },
                uomFields.self.tableColumn({
                    dataToRecord: (data: PartModel) => data.unitOfMeasureData,
                }),
                auth.companySettings.hasInventory
                    ? null
                    : {
                          field: 'cost',
                          headerName: 'Last Known Cost',
                          align: 'right',
                          valueGetter: ({ row }) => formatMoney(row.cost as PartModel['cost']),
                      },
            ].filter(Boolean) as DatagridColumnsProps<PartModel>['columns'],
            actions: inventoryView
                ? null
                : ({ row }, { children }) => [
                      deleteOneAction({
                          id: row.id,
                          notifyMessage: 'Successfully removed.',
                          children: (params) =>
                              children({
                                  ...params,
                                  Icon: Icons.DeleteCrossReference,
                                  title: 'Remove Cross-reference',
                              }),
                          ...removeReferenceProps,
                      }),
                  ],
        }
        const path =
            createPath({
                id: record.id,
                type: 'edit',
            }) + '/cross-references'
        const crossReferenceResource: ResourceType = {
            resource: path,
            name: 'part-cross-references',
        }
        return (
            <PageContent>
                <ResourceContextProviderWithClearEffect value={crossReferenceResource}>
                    <ListBase<PartModel> sort={defaultPartUsageSort}>
                        <ViewHeader title="Cross-reference">
                            <ViewHeader.Content>
                                <ListTotalBadge />
                            </ViewHeader.Content>
                            {inventoryView ? null : (
                                <ViewHeader.Content placement="rightMobile">
                                    <AddReferenceButton path={path} />
                                </ViewHeader.Content>
                            )}
                        </ViewHeader>
                        <List
                            disableExportButton
                            disableManageColumns
                            columnsCfg={columnsCfg}
                            cardsCfg={getCrossReferenceCardsCfg(navigateTo, auth, inventoryView)}
                            sortCfg={sortCfg}
                            listFTUProps={{
                                title: (
                                    <BoxContainer
                                        flexWrap="wrap"
                                        justifyContent="center"
                                    >
                                        <span>There are currently no</span>&nbsp;
                                        <span>cross-references.</span>
                                    </BoxContainer>
                                ),
                                secondaryTitle:
                                    'Once cross-reference parts are added, they will be displayed here.',
                                action: inventoryView ? null : (
                                    <AddReferenceButton
                                        path={path}
                                        renderElement={(onClick) => (
                                            <Typography
                                                color="primary.main"
                                                onClick={onClick}
                                                sx={{ cursor: 'pointer' }}
                                                variant="body1"
                                            >
                                                Add Cross-reference
                                            </Typography>
                                        )}
                                    />
                                ),
                                linkText: null,
                                imageSrc: images.crossReferenceFtu,
                            }}
                        />
                    </ListBase>
                </ResourceContextProviderWithClearEffect>
            </PageContent>
        )
    }),
)
export default CrossReference
