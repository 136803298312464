import { useMemo, useState } from 'react'

import { useResourcePreferences } from 'core/context'

import { type PinnedColumns } from '../types'
import { actionsField, checkboxField } from '../utils'

interface Props {
    initialPinnedColumns: PinnedColumns
    avatarSource: string
    mainField: string
}

interface Value {
    pinnedColumns: PinnedColumns
    setPinnedColumns: (value: PinnedColumns) => void
    isColumnPinnable: (column: string) => boolean
    isColumnPinned: (column: string) => boolean
}

// each passed right column can be unpinned
// left columns can not be unpinned
const usePinning = ({ initialPinnedColumns, avatarSource, mainField }: Props): Value => {
    const preferences = useResourcePreferences()
    const [pinnedColumns, setPinnedColumns] = useState<PinnedColumns>(() => {
        const value = preferences.value.pinnedColumns
        if (value) {
            return value
        }
        return {
            left: initialPinnedColumns?.left || [],
            right: initialPinnedColumns?.right || [],
        }
    })

    return useMemo<Value>(() => {
        const alwaysPinnedLeft = [checkboxField, avatarSource, mainField]
        const alwaysPinnedRight = [actionsField]

        const update = ({ left, right }: PinnedColumns) => {
            // select only columns that are not unpinnable
            left = left.filter((col) => !alwaysPinnedLeft.includes(col))
            // mui adds to the right side, we want to add to the left side. So we reverse the array
            right = right.filter((col) => !alwaysPinnedRight.includes(col)).reverse()

            const newPinnedColumns: PinnedColumns = {
                left,
                right,
            }

            setPinnedColumns(newPinnedColumns)

            preferences.updateLocal('pinnedColumns', newPinnedColumns)
            preferences.syncLocal()
        }

        const left = [...alwaysPinnedLeft, ...pinnedColumns.left]
        const right = [...pinnedColumns.right, ...alwaysPinnedRight]

        return {
            pinnedColumns: {
                left,
                right,
            },
            setPinnedColumns: update,
            isColumnPinnable: (column) => {
                if (alwaysPinnedLeft.includes(column) || alwaysPinnedRight.includes(column)) {
                    return false
                }

                return true
            },
            isColumnPinned: (column) => {
                return left.includes(column) || right.includes(column)
            },
        }
    }, [pinnedColumns, initialPinnedColumns, avatarSource, mainField, preferences])
}

export default usePinning
