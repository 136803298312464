import { useMemo, useState } from 'react'

import { type GridColumnVisibilityModel } from '@mui/x-data-grid'

import { useResourcePreferences } from 'core/context'

import { type DatagridColumnsProps } from '../types'
import { actionsField, checkboxField } from '../utils'

interface Props
    extends Pick<
        DatagridColumnsProps,
        'resetColumns' | 'mainField' | 'columns' | 'constantColumns'
    > {}

const useVisiblity = ({ resetColumns, mainField, columns, constantColumns }: Props) => {
    const preferences = useResourcePreferences()
    const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(() => {
        return Object.assign({}, resetColumns, preferences.value.visibleColumns)
    })

    const alwaysHiddenColumns = useMemo(() => {
        return (columns || []).reduce(
            (acc, col) => {
                if (col.alwaysHidden) {
                    acc[col.field as string] = false
                }
                return acc
            },
            {} as { [key: string]: false },
        )
    }, [columns])

    // Store only hideable columns
    const update = (model: GridColumnVisibilityModel) => {
        const { [checkboxField]: check, [actionsField]: action, ...restModel } = model

        if (constantColumns) {
            Object.keys(constantColumns).forEach((key) => {
                if (constantColumns[key]) {
                    delete restModel[key]
                }
            })
        }

        if (alwaysHiddenColumns) {
            Object.keys(alwaysHiddenColumns).forEach((key) => {
                delete restModel[key]
            })
        }

        if (mainField) {
            delete restModel[mainField as string]
        }

        const finalModel = {
            ...preferences.value.visibleColumns,
            ...restModel,
        }
        setVisibilityModel(finalModel)
        preferences.updateLocal('visibleColumns', finalModel)
        preferences.syncLocal()
    }

    const setColumnVisibility = (field: string, visibility: boolean) => {
        update({ ...visibilityModel, [field]: visibility })
    }

    const _visibilityModel = useMemo(() => {
        return {
            ...visibilityModel,
            ...constantColumns,
            ...alwaysHiddenColumns,
            [mainField]: true,
        }
    }, [visibilityModel, alwaysHiddenColumns])

    return {
        visibilityModel: _visibilityModel,
        setVisibilityModel: update,
        setColumnVisibility,
    }
}

export default useVisiblity
